<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		id="modal-planning"
		v-model="isOpen"
		hide-footer
		:size="$screen.width <= 992 ? 'md' : 'lg'"
		@hidden="emitCloseEvent"
	>
		<template #modal-title>
			<span v-if="!isMyPlannings" class="text-primary">
				<i class="fa fa-arrow-right" aria-hidden="true" />&nbsp;
				<router-link :to="toPath" v-html="selectedEvent.title" class="text-primary" />
			</span>
			<span v-else v-html="selectedEvent.title" />
		</template>
		<b-form @submit.prevent="handleSubmit">
			<div v-if="!commentMode">
				<b-row class="mb-4">
					<b-col cols="6">
						<change-status-planning :status-id="dataToEdit.status" @status:selected="selectStatus" />
					</b-col>
					<b-col v-if="isMyPlannings" cols="6">
						<div class="float-right">
							<b-button variant="success" class="mr-2" :disabled="disabledBtnStart" @click="handleClickRealStart">
								<i class="fa fa-play" aria-hidden="true"></i>
								{{ FormMSG(55, 'Start') }}
							</b-button>
							<b-button variant="danger" :disabled="disabledBtnFinish" @click="handleClickRealEnd">
								<i class="fa fa-stop-circle" aria-hidden="true"></i>
								{{ FormMSG(56, 'Finish') }}
							</b-button>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="isMyPlannings">
					<b-col>
						<div class="float-right">
							<p>{{ FormMSG(57, 'Real start') }} : {{ formatDisplayDate(dataToEdit.realStart) }}</p>
							<p>{{ FormMSG(58, 'Real end') }} : {{ formatDisplayDate(dataToEdit.realEnd) }}</p>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="selectedEvent.taskType && selectedEvent.taskType.name !== ''" class="mb-3">
					<b-col>
						<task-type-display :task-type="selectedEvent.taskType" />
					</b-col>
				</b-row>
				<b-row v-if="selectedEvent.client && selectedEvent.client.name">
					<b-col>
						<b-form-group :label="FormMSG(165, 'Client')">
							<b-form-input :value="selectedEvent.client.name" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="selectedEvent.clientId !== 0">
					<b-col>
						<b-form-group :label="FormMSG(166, 'Address')">
							<div style="display: flex">
								<b-form-input :value="addressFormatter" disabled style="width: 95%" />
								<b-button variant="primary" class="float-right" @click="handleClickLocalisation(selectedEvent)">
									<i class="icon-location-pin" />
								</b-button>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="selectedEvent.client && selectedEvent.client.phone">
					<b-col cols="6">
						<b-form-group :label="FormMSG(168, 'Phone')">
							<div style="display: flex">
								<b-form-input :value="selectedEvent.client.phone" disabled />
							</div>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group :label="FormMSG(169, 'Email')">
							<div style="display: flex">
								<b-form-input :value="selectedEvent.client && selectedEvent.client.email ? selectedEvent.client.email : ''" disabled />
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="mt-3 mb-4">
					<b-col>
						<div
							class="modal-planning-description"
							:class="{ 'height-150': !seeMore.showMore, 'height-auto': seeMore.showMore }"
							v-html="selectedEvent.description"
						/>
						<div v-if="seeMore.visible" class="text-center">
							<button type="button" class="btn-modal-planning-see-more" @click="seeMore.showMore = !seeMore.showMore">
								<span v-if="!seeMore.showMore">
									<i class="fa fa-chevron-down" aria-hidden="true" />
									{{ FormMSG(23, 'Show more') }}
								</span>
								<span v-if="seeMore.showMore">
									<i class="fa fa-chevron-up" aria-hidden="true" />
									{{ FormMSG(24, 'Show less') }}
								</span>
							</button>
						</div>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col cols="2">
						<p>{{ FormMSG(2, 'Event starts at') }}</p>
					</b-col>
					<b-col cols="6">
						<b-form-datepicker :locale="lang" v-model="dataToEdit.desiredStart" :disabled="isMyPlannings" />
					</b-col>
					<b-col cols="4">
						<b-form-timepicker
							v-model="dataToEdit.start"
							required
							@input="handleInputDataToEditStart"
							:placeholder="FormMSG(40, 'Select time')"
							:disabled="isMyPlannings"
						/>
					</b-col>
				</b-row>
				<b-row class="mb-3">
					<b-col cols="2">
						<p>{{ FormMSG(3, 'Event ends at') }}</p>
					</b-col>
					<b-col cols="6">
						<b-form-datepicker :locale="lang" v-model="dataToEdit.desiredStart" :disabled="isMyPlannings" />
					</b-col>
					<b-col cols="4">
						<b-form-timepicker
							v-model="dataToEdit.end"
							required
							@input="handleInputDataToEditEnd"
							:placeholder="FormMSG(40, 'Select time')"
							:disabled="isMyPlannings"
						/>
					</b-col>
				</b-row>
				<b-row v-if="!isMyPlannings">
					<b-col cols="12">
						<b-card no-body header-tag="header" footer-tag="footer">
							<template #header>
								<h6 class="mb-0 font-weight-bold">{{ FormMSG(41, 'Assigned users') }}</h6>
							</template>
							<b-card-body>
								<task-assigned-user col="6" :items="dataToEdit.taskResourceUsers" />
							</b-card-body>
							<template #footer v-if="canManagePlanning">
								<b-row>
									<b-col cols="12">
										<div class="tags-assigned">
											<task-user-selector
												:task-planning-id="selectedEvent.taskPlanningId"
												:assigned-to="dataToEdit.taskResourceUsers"
												@task-user-selector:userAdded="handleUserAdded"
												@task-user-selector:userDeleted="handleUserDeleted"
											/>
										</div>
									</b-col>
								</b-row>
							</template>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="!isMyPlannings">
					<b-col>
						<b-card no-body header-tag="header" footer-tag="footer">
							<template #header>
								<h6 class="mb-0 font-weight-bold">
									{{ FormMSG(30, 'Assigned teams') }}
								</h6>
							</template>
							<b-card-body>
								<task-assigned-team col="6" :items="dataToEdit.taskResourceTeams" :can-do-edit="!isMyPlannings && canManagePlanning" />
							</b-card-body>
							<template #footer v-if="canManagePlanning">
								<b-row>
									<b-col>
										<div class="tags-assigned">
											<task-team-selector
												:task-planning-id="selectedEvent.taskPlanningId"
												:assigned-team="dataToEdit.taskResourceTeams"
												@task-team-selector:teamAdded="handleTeamAdded"
												@task-team-selector:teamDeleted="handleTeamDeleted"
											/>
										</div>
									</b-col>
								</b-row>
							</template>
						</b-card>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-button variant="danger" @click="commentMode = true">
							{{ FormMSG(45, 'Comments') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="commentMode">
				<comments
					:key-value="{
						xid: selectedEvent.rootXid === '' ? selectedEvent.xid : selectedEvent.rootXid,
						imageParentId: parseInt(selectedEvent.rootId) === 0 ? parseInt(selectedEvent.id) : parseInt(selectedEvent.rootId)
					}"
				/>
			</div>
			<b-row class="mt-3">
				<b-col>
					<div class="float-right">
						<b-button v-if="commentMode" variant="success" class="mr-2" @click.prevent="commentMode = false">
							<b-icon icon="arrow-left-square" /> {{ FormMSG(25, 'Back') }}
						</b-button>
						<b-button :disabled="formBusy" @click="hideModal" class="mr-2">
							{{ FormMSG(10, 'Close') }}
						</b-button>
						<b-button v-if="!commentMode" variant="success" type="submit" :disabled="formBusy">
							<span v-if="formBusy">{{ FormMSG(43, 'Modification in progress') }}</span>
							<span v-else>{{ FormMSG(42, 'Save') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-form>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ChangeStatusPlanning from './ChangeStatusPlanning';
import CustomerSelector from '@/components/customer/CustomerSelector';
import TaskTypeSelector from '@/components/TaskType/TaskTypeSelector';
import TaskAssignedUser from '@/components/Task/TaskAssignedUser';
import TaskAssignedTeam from '@/components/Task/TaskAssignedTeam';
import { updatedTask } from '@/cruds/task.crud';
import dateMixin from '@/mixins/date.mixin';
import TaskUserSelector from '@/components/Task/TaskUserSelector';
import TaskTeamSelector from '@/components/Task/TaskTeamSelector';
import moment from 'moment';
import TaskTypeDisplay from './TaskTypeDisplay';
import Comments from '@/components/Chat/Comments';
import { addressFormatter } from '@/shared/fomaters';
import { store } from '@/store';

export default {
	name: 'ModalPlanning',

	components: {
		ChangeStatusPlanning,
		CustomerSelector,
		TaskTypeSelector,
		TaskAssignedUser,
		TaskAssignedTeam,
		TaskUserSelector,
		TaskTeamSelector,
		TaskTypeDisplay,
		Comments
	},

	mixins: [languageMessages, globalMixin, dateMixin],

	props: {
		open: { type: Boolean, default: false, required: true },
		selectedEvent: { type: Object, default: () => ({}), required: true },
		rawUsers: { type: Array, default: () => [], required: false },
		rawTeams: { type: Array, default: () => [], required: false }
	},

	data() {
		return {
			dataToEdit: {
				status: 0,
				start: '',
				end: '',
				desiredStart: '',
				taskResourceUsers: [],
				taskResourceTeams: [],
				realStart: '',
				realEnd: ''
			},
			// Variable to test if there is a change
			dataToEditOriginal: {
				status: 0,
				start: '',
				end: '',
				desiredStart: '',
				realStart: '',
				realEnd: ''
			},

			newTaskResouceUsers: [],
			newTaskResourceTeams: [],
			oldTaskResourceUsersToDelete: [],
			oldTaskResourceTeamsToDelete: [],

			isActiveUser: false,
			isActiveTeam: false,

			formBusy: false,

			seeMore: {
				visible: true,
				showMore: false
			},

			error: {
				start: false,
				end: false
			},

			commentMode: false
		};
	},

	computed: {
		addressFormatter() {
			if (this.selectedEvent.locationId === 0 && this.selectedEvent.client) {
				return addressFormatter(this.selectedEvent.client.address);
			}
			if (this.selectedEvent.location && this.selectedEvent.location.setAddress) {
				return addressFormatter(this.selectedEvent.location.setAddress);
			} else {
				return '';
			}
		},
		durationBetweenStartEnd() {
			return this.selectedEvent.end.getTime() - this.selectedEvent.start.getTime();
		},
		isMyPlannings() {
			if (this.$route.name === 'myPlannings') {
				return true;
			} else {
				return false;
			}
		},
		disabledBtnStart() {
			return this.dataToEdit.realStart !== '0001-01-01T00:00:00Z';
		},
		disabledBtnFinish() {
			return this.dataToEdit.realStart === '0001-01-01T00:00:00Z' || this.dataToEdit.realEnd !== '0001-01-01T00:00:00Z';
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		col() {
			return 4;
		},
		toPath() {
			if (this.selectedEvent.taskPlanningId) {
				return `/tasks/${this.selectedEvent.taskPlanningId}`;
			}
			return '';
		},
		canManagePlanning() {
			return store.canAssign();
		}
	},

	watch: {
		'dataToEdit.start'(val) {
			const start = new Date(`${this.dataToEdit.desiredStart} ${val}`);
			const end = new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.end}`);
		},
		selectedEvent(val) {
			this.dataToEdit.status = val.status;
			this.dataToEdit.dueDate = val.dueDate.split('T')[0];
			this.dataToEdit.start = val.start.formatTime();
			this.dataToEdit.end = val.end.formatTime();
			this.dataToEdit.desiredStart = val.start.format('YYYY-MM-DD');
			this.dataToEdit.realStart = val.realStart;
			this.dataToEdit.realEnd = val.realEnd;
			this.dataToEdit.taskResourceUsers = val.taskResourceUsers;
			this.dataToEdit.taskResourceTeams = val.taskResourceTeams;

			this.dataToEditOriginal.status = val.status;
			this.dataToEditOriginal.dueDate = val.dueDate.split('T')[0];
			this.dataToEditOriginal.start = val.start.formatTime();
			this.dataToEditOriginal.end = val.end.formatTime();
			this.dataToEditOriginal.desiredStart = val.start.format('YYYY-MM-DD');
			this.dataToEditOriginal.realStart = val.realStart;
			this.dataToEditOriginal.realEnd = val.realEnd;

			this.setVisibilitySeeMore(val.description);
		}
	},

	methods: {
		async handleClickLocalisation(event) {
			if (event.locationId === 0) {
				await this.displayMap(event.client.address);
			} else {
				await this.displayMap(event.location.setAddress);
			}
			this.hideModal();
		},
		handleTeamAdded(payload) {
			this.dataToEdit.taskResourceTeams.push(payload);
		},
		handleTeamDeleted(payload) {
			const findIndex = this.dataToEdit.taskResourceTeams.findIndex((tT) => parseInt(tT.id) === parseInt(payload));
			this.dataToEdit.taskResourceTeams.splice(findIndex, 1);
		},
		handleUserAdded(payload) {
			this.dataToEdit.taskResourceUsers.push(payload);
		},
		handleUserDeleted(payload) {
			const findIndex = this.dataToEdit.taskResourceUsers.findIndex((tU) => parseInt(tU.id) === parseInt(payload));
			this.dataToEdit.taskResourceUsers.splice(findIndex, 1);
		},
		handleInputDataToEditStart(payload) {
			const start = new Date(`${this.dataToEdit.desiredStart} ${payload}`);
			const end = new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.end}`);
			this.dataToEdit.end = moment(start).add(this.durationBetweenStartEnd, 'ms').format('HH:mm');
			this.dataToEditOriginal.end = moment(start).add(this.durationBetweenStartEnd, 'ms');
		},
		handleInputDataToEditEnd(payload) {
			const start = new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.start}`);
			const end = new Date(`${this.dataToEdit.desiredStart} ${payload}`);
			if (end < start) {
				this.dataToEdit.end = this.dataToEditOriginal.end;
				this.$bvToast.toast(this.FormMSG(185, 'The end date must not be less than the start date'), {
					title: 'Error',
					variant: 'danger',
					solid: true,
					toaster: 'b-toaster-top-center'
				});
			} else {
				this.error.end = false;
			}
		},
		/**
		 * @param date{String} Format : 0000-00-00T00:00:00Z
		 * @returns {string|*}
		 */
		formatDisplayDate(date, toString = false) {
			const tmpDate = new Date(date);
			if (date === '0001-01-01T00:00:00Z') {
				return this.FormMSG(67, 'Not defined');
			}
			if (!toString) {
				return `${tmpDate.format('DD/MM')} ${this.FormMSG(60, 'Hours')} : ${tmpDate.format('HH:mm')}`;
			} else {
				return tmpDate.format('YYYY-MM-DD');
			}
		},
		handleClickRealStart() {
			const date = new Date();
			this.dataToEdit.realStart = date.toISOString();
		},
		handleClickRealEnd() {
			const date = new Date();
			this.dataToEdit.realEnd = date.toISOString();
		},
		/**
		 *
		 * @param columns{Array}
		 * @param dataOriginal{Object}
		 * @param dataCopy{Object}
		 * @returns {Promise<{}|null>}
		 */
		async checkBeforeSendData(columns = [], dataOriginal = {}, dataCopy = {}) {
			let result = {};
			for (const column of columns) {
				if (dataOriginal[column] !== dataCopy[column]) {
					result[column] = dataCopy[column];
				}
			}
			if (JSON.stringify(result) !== '{}') {
				return result;
			}
			return null;
		},
		async handleSubmit() {
			this.confirmModal(this.sendData, this.FormMSG(66, 'Are you sure ?'));
		},
		async sendData() {
			this.formBusy = true;

			const duration = this.durationTimeBetweenTwoDate(`0001-01-01T${this.dataToEdit.start}:00Z`, `0001-01-01T${this.dataToEdit.end}:00Z`);

			// Processing for details task
			if (!this.isMyPlannings) {
				const chkStatusDesiredStart = await this.checkBeforeSendData(['status', 'desiredStart'], this.dataToEditOriginal, this.dataToEdit);
				if (chkStatusDesiredStart !== null) {
					await updatedTask(this.selectedEvent.id, {
						status: this.dataToEdit.status,
						putInPlanning: true,
						taskPlanningId: this.selectedEvent.taskPlanningId
					});
				}
			}

			if (!this.isMyPlannings) {
				if (this.checkBeforeSendData(['start', 'end'], this.dataToEditOriginal, this.dataToEdit) !== null) {
					await updatedTask(this.selectedEvent.id, {
						desiredStart: new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.start}`),
						duration,
						taskPlanningId: this.selectedEvent.taskPlanningId
					});
				}
			}

			if (this.isMyPlannings) {
				// Change real start for task planning
				if (this.checkBeforeSendData(['realStart'], this.dataToEditOriginal, this.dataToEdit)) {
					await updatedTask(this.selectedEvent.id, {
						start: this.dataToEdit.realStart,
						desiredStart: new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.start}`),
						duration,
						taskPlanningId: this.selectedEvent.taskPlanningId
					});
				}

				// Change real end for task planning
				if (this.checkBeforeSendData(['realEnd'], this.dataToEditOriginal, this.dataToEdit)) {
					await updatedTask(this.selectedEvent.id, {
						start: this.dataToEdit.realStart,
						end: this.dataToEdit.realEnd,
						desiredStart: new Date(`${this.dataToEdit.desiredStart} ${this.dataToEdit.start}`),
						duration,
						taskPlanningId: this.selectedEvent.taskPlanningId
					});
				}
			}

			// Emit event
			await this.emitEvent(this.dataToEdit.start, this.dataToEdit.end);

			this.formBusy = false;
			this.hideModal();
		},
		emitEvent(start, end) {
			const payload = {
				...this.selectedEvent,
				status: this.dataToEdit.status,
				start: new Date(`${this.dataToEdit.desiredStart} ${start}`),
				end: new Date(`${this.dataToEdit.desiredStart} ${end}`),
				taskResourceUsers: this.dataToEdit.taskResourceUsers,
				taskResourceTeams: this.dataToEdit.taskResourceTeams
			};
			if (this.dataToEdit.status !== 1) {
				this.$emit('change:info', payload);
			} else {
				this.$emit('refresh-plannings', payload);
			}
		},
		selectStatus({ value }) {
			this.dataToEdit.status = value;
		},
		emitCloseEvent() {
			this.commentMode = false;
			this.$emit('modal:close');
		},
		hideModal() {
			this.commentMode = false;
			this.emitCloseEvent();
			this.$bvModal.hide('modal-planing');
		},
		calculDuration(start, end) {
			const dateStart = new Date(start);
			const dateEnd = new Date(end);

			const response = dateEnd - dateStart;
			const responseToDate = new Date(response).format();
			return response;
		},
		onFocusTag(type) {
			if (type === 'user') {
				this.isActiveUser = true;
			} else if (type === 'team') {
				this.isActiveTeam = true;
			}
		},
		onBlurTag(type) {
			if (type === 'user') {
				this.isActiveUser = false;
			} else if (type === 'team') {
				this.isActiveTeam = false;
			}
		},
		setVisibilitySeeMore(str) {
			if (str.length < 375) {
				this.seeMore.visible = false;
			} else {
				this.seeMore.visible = true;
			}
			this.seeMore.showMore = false;
		}
	}
};
</script>

<style>
#modal-planning .text-primary a span {
	color: #00a09c !important;
}
.modal-planning-description {
	background-color: #f3f4f5;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #d5d6d6;
	color: #323635;
	overflow-y: hidden;
	transition: height 0.5s;
}
.btn-modal-planning-see-more {
	border-radius: 25px;
	border: 1px solid #d5d6d6;
	padding: 0 10px;
}
.height-150 {
	height: 150px;
	transition: height 0.5s;
}
.height-auto {
	height: auto;
	transition: height 0.5s;
}
</style>
